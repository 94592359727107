import * as React from 'react'

const BubbleTriple = (props) => {
  const { type, text } = props

  return (
    <div className={`bubble bubble-${type}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.37 85.42">
        <g>
          <path d="M208.37,42.1C208.23,18.71,189.16-.14,165.77,0c-12.12,.07-23,5.23-30.68,13.43C127.33,5.32,116.38,.29,104.27,.36c-12.29,.07-23.31,5.37-31,13.78C65.48,5.82,54.39,.65,42.1,.72,18.71,.86-.14,19.93,0,43.32c.14,23.39,19.21,42.24,42.6,42.1,12.29-.07,23.32-5.37,31-13.78,7.78,8.31,18.87,13.49,31.16,13.41,12.12-.07,23-5.23,30.68-13.43,7.77,8.11,18.72,13.14,30.83,13.07,23.39-.14,42.24-19.21,42.1-42.6Z" />
        </g>
      </svg>
      <span className="d-flex justify-content-center align-items-center">
        {text}
      </span>
    </div>
  )
}

export default BubbleTriple
