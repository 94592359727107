import React from 'react'

import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Seo from '../../components/seo'
import Layout from '../../components/layout'
import BubbleTriple from '../../components/svg-icons/bubble-triple'
import KlaviyoForm from '../../components/utilities/klaviyo-form'

const SMS = () => {
  return (
    <Layout className="sms-page sms-page__expo-west">
      <Seo title="Expo West Free Meati" noindex />
      <div className="bg-primary py-2 py-lg-6 py-xxl-8">
        <Container>
          <Row className="align-items-center">
            <Col lg="6" xl="5" xxl="7">
              <header className="text-center text-white mb-1 mb-sm-2 mb-lg-0 position-relative">
                <h1 className="font-swish fst-italic fw-bold mb-0">
                  <span className="spark-right d-inline-block position-relative">A Whole&nbsp;</span>
                  <span className="spark-left d-inline-block position-relative">Year of&nbsp;</span>
                  <span className="position-relative">
                    meati
                    <sup className="position-absolute">™</sup>
                    .&nbsp;
                  </span>
                  <span className="d-block position-relative">
                    For Free.
                  </span>
                </h1>
                <BubbleTriple type="success" text="Booth N1234" />
              </header>
            </Col>
            <Col lg="6" xl="7" xxl="5">
              <div className="p-2 p-sm-3">
                <div className="layered-form-frame rounded-4 bg-white">
                  <KlaviyoForm code="VGjqnk" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default SMS
